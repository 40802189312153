<template>
  <div v-bind="$attrs">
    <div class="flex items-center justify-between gap-2">
      <slot name="prepend" />
      <div class="flex justify-end">
        <span
          ref="spanRef"
          :class="cn('inline-block tracking-wider font-semibold tabular-nums tracking-tight', props.class)"
        >
          {{ output }}
        </span>
      </div>
      <slot name="append" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { TransitionPresets, useWindowScroll, useTransition } from "@vueuse/core";
import { cn } from "~/lib/utils";

interface NumberTickerProps {
  value: number;
  direction?: "up" | "down";
  duration?: number;
  delay?: number;
  decimalPlaces?: number;
  class?: string;
  transition?: keyof typeof TransitionPresets;
  scrollControl?: boolean;
  scrollStart?: number; // percentage
  scrollEnd?: number; // percentage
}

const props = withDefaults(defineProps<NumberTickerProps>(), {
  value: 0,
  direction: "up",
  delay: 0,
  duration: 1000,
  decimalPlaces: 0,
  transition: "easeOutExpo",
  scrollControl: false,
  scrollStart: 0,
  scrollEnd: 100,
});

const spanRef = ref<HTMLSpanElement>();
const { y: scrollY } = useWindowScroll();
const currentValue = ref(0);

const emit = defineEmits<{
  (e: "update:value", value: number): void;
}>();

// Get element position
const { top } = useElementBounding(spanRef);

// Create a transition for the value with proper configuration
const transitionValue = useTransition(currentValue, {
  duration: props.duration,
  transition: TransitionPresets[props.transition],
  delay: props.delay,
  disabled: props.scrollControl,
});

// Update the computed value when scroll position changes
watch([scrollY, top], () => {
  if (!props.scrollControl) return;

  const windowHeight = window.innerHeight;
  const elementPosition = top.value;
  const currentScroll = scrollY.value;

  const viewportPosition = ((windowHeight - elementPosition + currentScroll) / windowHeight) * 100;
  
  const progress = Math.max(0, Math.min(1, 
    (viewportPosition - props.scrollStart) / (props.scrollEnd - props.scrollStart)
  ));

  currentValue.value = props.direction === "up" 
    ? props.value * progress 
    : props.value * (1 - progress);

  emit("update:value", currentValue.value);
});

// Format the output with proper number formatting
const output = computed(() => {
  const value = Math.abs(currentValue.value); // Handle negative numbers properly
  const formattedValue = value.toFixed(props.decimalPlaces);
  const sign = currentValue.value < 0 ? '-' : '';
  
  return sign + new Intl.NumberFormat("en-US", {
    minimumFractionDigits: props.decimalPlaces,
    maximumFractionDigits: props.decimalPlaces,
  }).format(Number(formattedValue));
});
</script>